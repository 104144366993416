
.heading{

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 32px;
line-height: 48px;

color: #DD6031;
text-align: center;

}

.container{
    width: 100%;
    min-height:1300px;
    margin: auto;

    background: #04132F;
    box-shadow: 0px 4px 12px rgb(0 0 0 / 25%);
}

.right{
    width:47%;
    height:350px;
    margin:auto;
    visibility: visible;
    box-shadow: 0 0 65px rgba(237,78,80,.4), 0 0 0 1px rgba(255,255,255,.1), 0 2px 2px rgba(0,0,0,.03), 0 4px 4px rgba(0,0,0,.04), 0 10px 8px rgba(0,0,0,.05), 0 15px 15px rgba(0,0,0,.06), 0 30px 30px rgba(0,0,0,.07), 0 70px 65px rgba(0,0,0,.09);
    animation: .6s cubic-bezier(0.16, 1, 0.3, 1) home-codeql-step-1 forwards;
}

.travelWebsiteImage{
max-width:100%;
height:350px;
object-fit: cover;

}

.expenseImage{
max-width:100%;
height:490px;
object-fit: cover;

}

.description{

width:80%;
margin:auto;
margin-top:2%;
display:flex;
}

.left{
color: white;
width: 50%;
font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 30px;
margin:auto;
margin:5%;

text-align: left;
color: #FFFFFF;
}

.projectTitle{
color: white;
max-width:50%;
width: 50%;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 30px;
margin:auto;
padding:2%;
color: #00AFB5;
}

@media screen and (max-width:500px){
    .description{


        flex-direction: column;
}

.right{
    width:100%;
    height:200px;
}
.travelWebsiteImage{
max-width:100%;
height:200px;
object-fit: cover;

}
.left{
width:100%;
font-size: 14px;
line-height: 20px;
color: #FFFFFF;
margin:0%;

}

}
.figma{
color:blue;
text-decoration: underline;
}

