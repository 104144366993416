
.heading{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 32px;
line-height: 48px;

color: #DD6031;
text-align: center;
}

.container{
width: 60%;
    display: flex;
    margin: auto;

    background: #04132F;
    box-shadow: 0px 4px 12px rgb(0 0 0 / 25%);
}

.aboutImg{
    height:250px;
    width: 240px;
    background-size: cover;
}
.highlight{
color: #00AFB5;
}

.details{
margin-top:2%;
display: flex;
    justify-content: space-around;
}

.paragraph{
width: 50%;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
text-align:left;

color: #FFFFFF;
}
@media screen and (max-width:500px){
    .aboutImg{
    display:none;
    }

.paragraph{
font-weight: 400;
width:80%;
font-size: 14px;
line-height: 20px;

color: #FFFFFF;
}
}

@media screen and (max-width:1470px){
    .aboutImg{
    display:none;
    }
    .paragraph{
font-weight: 400;
width:60%;
font-size: 14px;
line-height: 20px;

color: #FFFFFF;
}


}

