*{
padding:0;
}

body{
background: #0D1937;

}
body{
 overflow-x: hidden;
}

.custom-shape-divider-bottom-1653105854 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1653105854 svg {
    position: relative;
    display: block;
    width: calc(124% + 1.3px);
    height: 167px;
}

.custom-shape-divider-bottom-1653105854 .shape-fill {
    fill: #0D1937;
}

.home{
position: relative;
background: linear-gradient(167.24deg, #162A5E 9.23%, #2D1A44 85.73%);
min-height: 50vh;
    width: 100%;
}
.profile{
width:100%;
}

.details{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5%;
    margin-top: 2%;

}

.first-line{
font-family: poppins, sans-serif;
font-style: normal;
font-weight: 400;
font-size: 32px;
line-height: 48px;

color: #DD6031;
}
.my-name{
margin-top:-1%;
font-family: 'Tiro Devanagari Marathi';
font-style: normal;
font-weight: 400;
font-size: 49px;
line-height: 65px;

color: #FFFFFF;
}

.download-btn{

    background: #DD6031;
    border-radius: 12px;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: white;
    width: 183px;
    height: 42px;
    text-decoration:none;
    transition: 0.3s ease-in;
}
.download-text{
vertical-align:middle;

}
.download-btn:hover{
transform:scale(1.1)

}

.dotted-pattern{

display: none;
  grid-template-columns: auto auto auto auto auto auto;
  padding: 10px;
  max-width: 100px;
  row-gap:25%;
}

.stick-pattern{
position: relative;
margin-top:17%;
}

.dotted-row{

border-radius:50%;
width: 9.55px;
height: 9.55px;

background: rgba(119, 159, 161, 0.2);
}

.home-page-upper{
    display: flex;
    justify-content: space-around;
    margin: auto;
}
.home-profile{
width:650px;
height:200px;
object-fit: cover;}

@media screen and (max-width:900px){


.home-profile{
display:none;
width:350px;
height:200px;
object-fit: cover;}



}

@media screen and (max-width:1350px){

.first-line{
font-size: 22px;

color: #DD6031;
}
.my-name{
font-size: 24px;

}

.home-profile{
width:550px;
height:200px;
object-fit: cover;}


}

