@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');


.right,.logo{
padding:1%;
}

.logo a{
color: white;
    font-size: 20px;
    font-family: Dancing Script, sans-serif;
    text-decoration: none;


}

.right a{

    margin: 0 25px;
    text-decoration: none;
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    font-family: poppins;
    letter-spacing:2.5px;

}
.container{
    margin: auto;
    display: flex;
    justify-content: space-between;

}

.hamburger{
display:none;
height:25px;

}

.hamburger div{
    transition: 0.4s ease-in;

}

.hamItem{
    width: 47px;
    height: 4px;
    margin:9px;
    background: white;

}

.items{
    display: flex;
    position:relative;

}
.items li{
    text-decoration:none;
    list-style-type:none;
    cursor: pointer;
}
@media screen and (max-width:900px){
        .right a{
        flex-direction: column;
        }
        .items{

                flex-direction: column;
                position:fixed;
                top:1vh;
                right:0;
                width:46%;
                height:29%;
                background:#0d1937;
                transform: translateX(100%);
                transition: 0.5s ease-in;

        }
        .item{
        text-align:left;
        margin-top:5%;
        }

        .hamburger{
        display: block;

        }

        .container{
    background: #0d1937;
        }


}

.activeMenu{
     transform: translateX(0);
}

.toggleIcon .line1{
    transform:rotate(-45deg) translate(-12px,5px);
}
.toggleIcon .line2{opacity:0}
.toggleIcon .line3{
      transform:rotate(45deg) translate(-12px,-5px);
}